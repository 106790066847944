<template>
    <OMediaQueryProvider v-slot="{ isTouch }">
        <FieldFilterTouch v-if="isTouch && !pointerOnly"
            @vue:mounted="() => filterComponentResolved()"
            :columnName="columnName"
            :filterObject="filterObject"
            :filterItem="filterItem"
            :searchOnEnter="searchOnEnter"
            :inputClassName="inputClassName"
            :placeHolder="placeHolder"
            :hideColumn="hideColumn"
            :hideOperators="hideOperators"
            :hideDropdown="hideDropdown" />

        <FieldFilterPointer v-if="!isTouch || pointerOnly"
            @vue:mounted="() => filterComponentResolved()"
            :columnName="columnName"
            :filterObject="filterObject"
            :filterItem="filterItem"
            :searchOnEnter="searchOnEnter"
            :inputClassName="inputClassName"
            :placeHolder="placeHolder"
            :hideColumn="hideColumn"
            :hideDropdown="hideDropdown"
            :hideOperators="hideOperators"
            :autoSearchDebounce="autoSearchDebounce"
            :autofocus="autofocus"
            v-bind="$attrs"/>
    </OMediaQueryProvider>
</template>

<script setup>
    import { defineProps, onUnmounted } from 'vue';
    import OMediaQueryProvider from 'o365.vue.components.MediaQueryProvider.vue';
    import useAsyncComponent from 'o365.vue.composables.AsyncComponent.ts';

    const FieldFilterTouch = useAsyncComponent('o365.vue.components.FieldFilterTouch.vue');
    const FieldFilterPointer = useAsyncComponent('o365.vue.components.FieldFilterPointer.vue');

    const props = defineProps({
        columnName: String,
        filterObject: null,
        filterItem: null,
        searchOnEnter: Boolean,
        inputClassName: String,
        placeHolder: String,
        hideColumn: {
            type: Boolean,
            default: false
        },
        hideDropdown: {
            type: Boolean,
            default: false
        },
        hideOperators:{
            type: Boolean,
            default: false
        },
        autofocus: {
            type: Boolean,
            default: false
        },
        /** Use pointer field filter only */
        pointerOnly: {
            type: Boolean,
            default: false
        },
        autoSearchDebounce: {
            type: [Number, Boolean],
        },

    });


    let componentResolve = null;
    const componentResolved = new Promise(res => { componentResolve = res; });

    function filterComponentResolved() {
        if (componentResolve) { 
            componentResolve();
            componentResolve = null;
         }
    }

    onUnmounted(() => {
        filterComponentResolved();
    });

    defineExpose({ componentResolved });
</script>

<script>
    import OFilterDropdown from 'o365.vue.components.FilterDropdown.vue';
    export default {
        components: { OFilterDropdown }
    }
</script>
